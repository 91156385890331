<template>
  <div style="background: #ebedf0;color: #000;">
    <van-row class="vip">
      <img class="user-poster" src="../../assets/img/beijintu.png" />
      <div class="bot_tou">
        <div class="touxiang">
          <div class="tou_a">
            <img class="toux" v-if="!userInfo" @click="geren" src="../../assets/img/weidenglu.png" alt />
            <img v-else class="toux" :src="userInfo.avatar?userInfo.avatar:''" @click="geren2" alt />
            <div class="xin_b" v-if="list.partnerLevel == 2">
              <img class="hehuo" src="../../assets/img/yghehuoren.png" alt />
            </div>
            <div class="xin_b" v-if="list.partnerLevel == 1">
              <img class="hehuo" src="../../assets/img/hhr.png" alt />
            </div>
            <div class="xin_b" v-if="list.partnerLevel == 3">
              <img class="hehuo" src="../../assets/img/gudong.png" alt />
            </div>
            <div class="xin_b" v-if="list.partnerLevel == 4">
              <img class="hehuo" src="../../assets/img/baoxian.png" alt />
            </div>
          </div>
          <!--  -->
        </div>
        <div class="chenz">
          <!-- <div class="w_name">灰太狼</div> -->
          <div class="w_name" v-if="!userInfo">未登录</div>
          <div v-else class="w_name">
            <img v-if="userInfo.greenVip" src="../../assets/img/lvz.png" alt="">
            {{userInfo.realName?userInfo.realName:''}}
          </div>
          <div class="chengzhang">
            <span v-if="userInfo.greenVip">绿钻会员</span>
            <span v-else>当前成长值 0</span>
          </div>
        </div>
        <div class="yuer_mien" @click="toliushui">
          <div class="yuer_h1" v-if="!list.onlineBalance">0</div>
          <div class="yuer_h1" v-else>{{list.onlineBalance?list.onlineBalance.toFixed(2):'0'}}</div>
          <!-- <div class="yuer_h1">500.00</div> -->
          <div class="yuer_h2">线上储值金</div>
        </div>

        <div class="tuokema" @click="ist = true">
          <div class="yuer_h1">0</div>
          <div class="yuer_h2">积分</div>
        </div>
        <div class="tuokema" @click="tosoayisao">
          <img class="soa" src="../../assets/img/saoyisao.png" alt />
          <div class="yuer_h2" style>扫一扫</div>
        </div>
      </div>
      <div class="huiyuan_tiao" v-if="shangjia.id == 208 || shangjia.id == 484"></div>
      <div class="huiyuan_tiao" @click="kaitong" v-else>
        <img class="cimg" src="../../assets/img/huiyuan.png" alt />
        <span class="chetie">
          <span class="hui">vip车贴会员</span>
          <span class="ka_lis" style="margin: 0 5px">|</span>
          <span class="ka_lis"></span>
        </span>
      </div>
    </van-row>
    <van-row class="user-linkstop m_lis" style="margin-top: 10px;">
      <div class="list_a list_top" @click="changeRoute('Lucky_draw')" style="font-size:13px;color:#333;">
        <div class="count" v-if="!list.luckCount">0</div>
        <div class="count" v-else>{{list.luckCount?list.luckCount : "0"}}</div>抽奖
      </div>

      <div class="list_a list_top" @click="changeRoute('youhui_list')" style="font-size:13px;color:#333;" badge="5">
        <div class="count" v-if="!list.activityCount">0</div>
        <div class="count" v-else>{{list.activityCount?list.activityCount : "0"}}</div>优惠券
        <van-tag class="zuobiao" round type="primary" color="red" v-if="list.activityGarageCount>1">{{list.activityGarageCount}}</van-tag>
      </div>
      <div class="list_a list_top" @click="changeRoute('water_list')" style="font-size:13px;color:#333;">
        <div class="count" v-if="!list.balance">0</div>
        <div class="count" v-else>{{list.balance?list.balance : "0"}}</div>本地储值金
        <van-tag class="zuobiao" round type="primary" color="red" v-if="list.balanceGarageCount>1">{{list.balanceGarageCount}}</van-tag>
      </div>
      <div class="list_a list_top" @click="changeRoute('meal')" style="font-size:13px;color:#333;">
        <div class="count" v-if="!list.comboCount">0</div>
        <div class="count" v-else>{{list.comboCount?list.comboCount : "0"}}</div>套餐次卡
        <van-tag class="zuobiao" round type="primary" color="red" v-if="list.comboGarageCount>1">{{list.comboGarageCount}}</van-tag>
      </div>

      <div class="list_a list_top" @click="changeRoute('make_list')" style="font-size:13px;color:#333;">
        <div class="count" v-if="!list.appointmentCount">0</div>
        <div class="count" v-else>{{list.appointmentCount?list.appointmentCount : "0"}}</div>预约记录
      </div>
    </van-row>
    <van-row class="m_yy_list m_lis" style>
      <div class="left m_gu">
        <div class="cika_name">
          <div class="left fuwu_guwen">服务顾问</div>
          <div class="fuwu_biao">
            <img class="fuwtu" src="../../assets/img/hehuobiao.png" alt />
            <span style="vertical-align: middle;">五星客户服务</span>
          </div>
        </div>
        <div class="cika_name">
          <div class="left zuotou">
            <img class="guwen_tou" v-if="form.image" :src="form.image" alt />
            <img class="guwen_tou" v-else src="../../assets/img/touxiang.png" alt />
            <span>{{form.name?form.name:'暂无'}}</span>
          </div>
          <div class="right">
            <a :href="'tel:'+form.phone">
              <img class="dian_img" src="../../assets/img/dian_a.png" alt />
            </a>
            <a :href="'tel:'+'18680091601'">
              <img class="dian_img" src="../../assets/img/dian_b.png" alt />
            </a>
          </div>
        </div>
      </div>
      <div class="right m_gu">
        <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white" v-if="aiisok">
          <van-swipe-item v-for="(item,index) in form.customerCarDOS" :key="index" @click="tobaoyang(item)">
            <div class="cika_name">
              <div class="left fuwu_guwen">爱车要项</div>
              <div class="fuwu_biao chesp">
                <span style="vertical-align: middle;">{{item.carNo}}</span>
              </div>
            </div>
            <div class="xian_xin mts">
              <span class="left qi_a">交强险到期</span>
              <span class="left" :class="item.insuranceExpireT<=45?'acvred':''">{{item.insuranceExpire?item.insuranceExpire:'暂无'}}</span>
              <!-- <span class="right">紧急</span> -->
            </div>
            <div class="xian_xin">
              <span class="left qi_a">商业险到期</span>
              <span class="left" :class="item.businessExpireT<=45?'acvred':''">{{item.businessExpire?item.businessExpire:'暂无'}}</span>
              <!-- <span class="right">紧急</span> -->
            </div>
            <div class="xian_xin">
              <span class="left qi_a">年审到期</span>
              <span class="left" :class="item.yearExpireT<=45?'acvred':''">{{item.yearExpire?item.yearExpire:'暂无'}}</span>
              <!-- <span class="right">紧急</span> -->
            </div>
            <div class="xian_xin">
              <span class="left qi_a">保养到期</span>
              <span class="left" :class="item.suggestMaintenanceDateT<=45?'acvred':''">{{item.suggestMaintenanceDate?item.suggestMaintenanceDate:'暂无'}}</span>
              <!-- <span class="right">紧急</span> -->
            </div>
          </van-swipe-item>
        </van-swipe>

        <div v-else @click="totianjiacar">
          <div class="cika_name">
            <div class="left fuwu_guwen">爱车要项</div>
            <div class="fuwu_biao chesp">
              <span style="vertical-align: middle;">请添加</span>
            </div>
          </div>
          <div class="xian_xin mts">
            <span class="left qi_a">交强险到期</span>
            <span class="left">暂无</span>
            <!-- <span class="right">紧急</span> -->
          </div>
          <div class="xian_xin">
            <span class="left qi_a">商业险到期</span>
            <span class="left">暂无</span>
            <!-- <span class="right">紧急</span> -->
          </div>
          <div class="xian_xin">
            <span class="left qi_a">年审到期</span>
            <span class="left">暂无</span>
            <!-- <span class="right">紧急</span> -->
          </div>
          <div class="xian_xin">
            <span class="left qi_a">保养到期</span>
            <span class="left">暂无</span>
            <!-- <span class="right">紧急</span> -->
          </div>
        </div>
      </div>
    </van-row>
    <van-row class="user-links m_lis">
      <div class="list_c">
        <div class="left dingdan_name" >我的订单</div>
        <div class="right dingdan_quanbu">
          <router-link :to="{path:'/order',query:{status:'-1'}}">
            <span style="vertical-align: middle;">全部订单</span>
          </router-link>
          <van-icon name="arrow" style="display: inline;vertical-align: middle;font-size: 14px;" />
        </div>
      </div>
      <div class="list_a" v-for="(item, index) in imgList2" :key="index" @click="changeRoute(item.url,item.status)" style="font-size:13px;color:#333;">
        <van-icon :name="item.img" size="30" style="padding-bottom: 0%;" />
        {{item.title}}
      </div>
    </van-row>

    <van-row class="user-links m_lis">
      <div class="list_a" v-for="(item, index) in imgList3" :key="index" @click="changeRoute(item.url)" style="font-size:13px;color:#333;">
        <van-icon :name="item.img" size="30" style="padding-bottom: 0%;" />
        {{item.title}}
      </div>
    </van-row>
    <van-row class="m_yy_list m_lis" style>
      <div class="left m_yu_a" @click="totcyuyue">
        <div class="left">
          <div class="cika_name">套餐次卡使用预约</div>
          <div class="sj_name">预约时间立即确认</div>
        </div>
        <div class="right" style="height: 50px;">
          <img class="yu_tu" src="../../assets/img/yuyue.png" alt />
        </div>
      </div>
      <div class="right m_yu_a" @click="tozxyuyue">
        <div class="left">
          <div class="cika_name">到店维修预约</div>
          <div class="sj_name">需要厂家在线确认</div>
        </div>
        <div class="right" style="height: 50px;">
          <img class="yu_tu" src="../../assets/img/yuyue2.png" alt />
        </div>
      </div>
    </van-row>
    <!-- v-if="list.partnerLevel != 0" -->
    <van-row class="m_yy_list m_lis" style v-if="list.partnerLevel != 0">
      <img class="kasimg" @click="tokashangcheng" src="../../assets/img/kashangcheng.png" alt />
    </van-row>
    <span v-if="ist">{{result}}</span>
    <van-dialog v-model="show" title="输入兑换码" @confirm="duihuan" style="margin: auto;" show-cancel-button>
      <input type="text" v-model="excode" class="xuanzheqi" />
    </van-dialog>
    <van-dialog title v-model="showts" @confirm="duihuan" confirm-button-text="关闭" confirm-button-color="#ee0a24" style="margin: auto;text-align: center">
      <div class="ch1">兑换成功！</div>
      <div class="cckk">
        请前往
        <span style="color: #3ac29f;">优惠券</span>中查看
      </div>
    </van-dialog>
    <div style="height: 60px;"></div>
    <!-- <div>{{this.$store.state.appid}}</div> -->
    <tabbar :active="4"></tabbar>
  </div>
</template>

<script>
import tabbar from "../../components/tabbar.vue";
import orderModel from "../../api/order";
import userModel from "../../api/user";

import {
  manualbuy,
  serviceadviser,
  choicegrant,
  handleorder,
  workTimesave,
} from "../../api/check";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
import Bus from "../../components/bus.js";
import { ShareImpl } from "../../components/weixinApi.js";

export default {
  // mixins: [preventBack], //注入
  components: {
    tabbar,
  },

  data() {
    return {
      userInfo: {},
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      active: 0,
      form: {},
      show: false,
      showts: false,
      aiisok: true,
      list: {},
      result: "",
      ist: false,
      value1: 0,
      excode: "",
      imgList2: [
        {
          title: "待付款",
          img: require("../../assets/img/dindan1.png"),
          url: "order",
          status: "0",
        },
        {
          title: "待预约",
          img: require("../../assets/img/dindan2.png"),
          url: "order",
          status: "1",
        },
        {
          title: "待安装",
          img: require("../../assets/img/dindan3.png"),
          url: "tuoke",
          url: "order",
          status: "2",
        },
        {
          title: "待评价",
          img: require("../../assets/img/dindan4.png"),
          url: "",
        },
        {
          title: "售后/退货",
          img: require("../../assets/img/dindan5.png"),
          url: "",
        },
      ],
      imgList3: [
        {
          title: "消费记录",
          img: require("../../assets/img/xiaofei1.png"),
          url: "consume",
        },
        {
          title: "会员办理",
          img: require("../../assets/img/xiaofei2.png"),
          url: "member",
        },
        {
          title: "套餐办理",
          img: require("../../assets/img/xiaofei3.png"),
          url: "package",
        },
        {
          title: "储值卡充值",
          img: require("../../assets/img/xiaofei4.png"),
          url: "value_card",
        },
        {
          title: "拓客业务",
          img: require("../../assets/img/xiaofei5.png"),
          url: "tuoke",
        },
      ],
    };
  },
  watch: {
    // $route(to, from) {
    //   this.$router.go(0);
    //   // console.log(to)
    //   // console.log(from)
    // }
  },
  methods: {
    changeRoute(url, status) {
      console.log(url);
      console.log(status);
      if (url == "tuoke") {
        // sessionStorage.getItem("usedCar") == 3
        if (
          this.list.partnerLevel == 5 &&
          (this.shangjia.id == 208 || this.shangjia.id == 484)
        ) {
          this.$router.push({
            path: "/My_performance",
          });
        } else {
          this.$router.push({
            path: url,
            query: {
              status: status,
            },
          });
        }
      } else {
        this.$router.push({
          path: url,
          query: {
            status: status,
            shopisok: true,
          },
        });
      }
    },
    setDaka(time) {
      var data = {
        codeNumber: time,
        deviceId: this.userInfo.id,
        gid: this.shangjia.id,
        latitude: "",
        longitude: "",
        realName: this.userInfo.realName,
        uid: this.userInfo.id,
      };
      workTimesave(data).then((e) => {
        if (e.code == 200) {
          this.$toast.success("打卡成功！");
        }
      });
    },
    strDateTime(str) {
      var reg =
        /^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2}) (\d{1,2}):(\d{1,2}):(\d{1,2})$/;
      var r = str.match(reg);
      if (r == null) return false;
      var d = new Date(r[1], r[3] - 1, r[4], r[5], r[6], r[7]);
      return (
        d.getFullYear() == r[1] &&
        d.getMonth() + 1 == r[3] &&
        d.getDate() == r[4] &&
        d.getHours() == r[5] &&
        d.getMinutes() == r[6] &&
        d.getSeconds() == r[7]
      );
    },
    tobaoyang(item) {
      this.$router.push({
        path: "insurance",
        query: {
          item: JSON.stringify(item),
        },
      });
    },
    //跳转流水
    toliushui() {
      this.$router.push({
        path: "water_subsidiary",
        query: {
          xsiosk: true,
          gid: this.shangjia.id,
          customerId: this.userInfo.id,
          cname: "线上",
        },
      });
    },
    //个人资料
    geren() {
      localStorage.setItem("beforeUrl", this.$route.path);
      const appid = sessionStorage.getItem("appid");
      const redirect_uri = encodeURIComponent(
        "https://shopping.car-posthouse.cn/auths"
      );
      window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
    },
    //跳转套餐预约
    totcyuyue() {
      this.$router.push({
        name: "select",
      });
    },
    //跳转在线预约
    tozxyuyue() {
      this.$router.push({
        path: "make_list_details",
        query: {},
      });
    },
    //跳转卡券商城
    tokashangcheng() {
      this.$router.push({
        path: "tuoke_shopping",
        query: {},
      });
    },
    //获取服务顾问信息
    formadviser() {
      var _this = this;
      serviceadviser({ gid: this.shangjia.id }).then((e) => {
        if (e.code == 200) {
          // console.log(_this.datedifference("2022-02-16", "2022-02-20"));
          // console.log(new Date(+new Date() + 8 * 3600 * 1000).toJSON().substr(0, 10).replace("T", " "))
          var Time = new Date(+new Date() + 8 * 3600 * 1000)
            .toJSON()
            .substr(0, 10)
            .replace("T", " ");

          var list = e.data.customerCarDOS;
          if (e.data.customerCarDOS.length > 0) {
            for (var i in list) {
              if (list[i].insuranceExpire) {
                list[i].insuranceExpireT = _this.datedifference(
                  Time,
                  list[i].insuranceExpire
                );
              } else {
                list[i].insuranceExpireT = 99;
              }
              if (list[i].businessExpire) {
                list[i].businessExpireT = _this.datedifference(
                  Time,
                  list[i].businessExpire
                );
              } else {
                list[i].businessExpireT = 99;
              }
              if (list[i].yearExpire) {
                list[i].yearExpireT = _this.datedifference(
                  Time,
                  list[i].yearExpire
                );
              } else {
                list[i].yearExpireT = 99;
              }
              if (list[i].suggestMaintenanceDate) {
                list[i].suggestMaintenanceDateT = _this.datedifference(
                  Time,
                  list[i].suggestMaintenanceDate
                );
              } else {
                list[i].suggestMaintenanceDateT = 99;
              }
            }
          } else {
            this.aiisok = false;
          }
          this.form = e.data;

          // console.log(this.form);
        }
      });
    },

    datedifference(sDate1, sDate2) {
      //sDate1和sDate2是2006-12-18格式
      var dateSpan, tempDate, iDays;
      sDate1 = Date.parse(sDate1);
      sDate2 = Date.parse(sDate2);
      dateSpan = sDate2 - sDate1;
      // dateSpan = Math.abs(dateSpan);
      // console.log(dateSpan / (24 * 3600 * 1000))
      iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
      return iDays;
    },
    //兑换
    duihuan() {
      var _this = this;

      if (this.excode == "") {
        this.$toast("请先输入兑奖码！");
        return;
      }

      const data = {
        carNo: this.chexin.carNo,
        name: this.userInfo.realName,
        phone: this.chexin.phone,
        number: this.excode,
      };
      handleorder(data).then((e) => {
        if (e.code == 200) {
          _this.$toast.success("兑换成功！");
          _this.excode = "";
          _this.onLoad();
        }
      });
      // const data = {
      //   carNo: this.chexin.carNo,
      //   couponNumber: this.excode,
      //   customerId: this.userInfo.customerId,
      //   gid: this.shangjia.id,
      //   name: this.userInfo.realName,
      //   uid: null
      // };
      // orderModel.coupontransfer(data).then(e => {
      //   if (e.code == 200) {
      //     _this.$toast.success("获取赠送成功！");
      //     _this.excode = "";
      //     _this.onLoad();
      //   }
      // });
    },
    onLoad() {
      const data = {
        gid: this.shangjia.id,
        uid: this.userInfo.id,
      };
      orderModel
        .count(data)
        .then((e) => {
          // loading.clear();
          console.log(e.data);
          this.list = e.data;
          this.userInfo.customerId = e.data.customerId;
          sessionStorage.setItem("partnerLevel", e.data.partnerLevel);
          localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        })
        .catch(() => loading.clear());
    },

    kaitong() {
      this.$router.push({
        path: "gift_bag",
      });
    },
    geren2() {
      window.location.href = "/personal";
      // this.$router.push({
      //   path: "personal"
      // });
    },
    getSearchString(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      return obj[key];
    },
    totianjiacar() {
      window.location.href = "/authentication";
    },
    tosoayisao() {
      var _this = this;
      // var search = "?couponNumber=3680189628&sellRule=1";
      // if (
      //   _this.chexin == null ||
      //   _this.chexin.carNo == "" ||
      //   _this.chexin.carNo == null
      // ) {
      //   _this.$toast("请先认证车牌再使用扫码功能！");
      //   setTimeout(() => {
      //     window.location.href = "/authentication";
      //   }, 1000);

      //   return;
      // }
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          console.log(res);
          // alert(res.resultStr)
          // _this.result = res.resultStr;
          var search = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
          if (_this.strDateTime(search)) {
            //上班打卡
            _this.setDaka(search);
            return;
          }
          var couponNumber = _this.getSearchString("couponNumber", search); //结果：18
          var id = _this.getSearchString("id", search); //结果：18
          var sellRule = _this.getSearchString("sellRule", search); //结果：18
          var uid = _this.getSearchString("uid", search); //结果：18
          var aid = _this.getSearchString("aid", search); //结果：18
          var type = _this.getSearchString("type", search); //结果：18
          var gid = _this.getSearchString("gid", search); //结果：18
          // _this.$toast("走到了这2");
          //转赠券
          if (sellRule == 1) {
            var data = {
              carNo: _this.chexin.carNo,
              couponNumber: couponNumber,
              customerId: _this.userInfo.customerId,
              gid: _this.shangjia.id,
              name: _this.userInfo.realName,
              uid: uid,
            };
            orderModel.coupontransfer(data).then((e) => {
              if (e.code == 200) {
                if (type == 7) {
                  _this.$toast.success("充值成功！");
                } else {
                  _this.$toast.success("获取赠送成功！");
                }

                _this.onLoad();
              }
            });
          } else if (sellRule == 2) {
            // const data = {
            //   carNo: this.chexin.carNo,
            //   couponNumber: couponNumber,
            //   customerId: this.userInfo.customerId,
            //   gid: this.shangjia.id,
            //   name: this.userInfo.realName,
            //   uid: uid
            // };
            // orderModel.couponsell(data).then(e => {
            //   if (e.code == 200) {
            //     console.log(res);
            //     if (typeof WeixinJSBridge === "undefined") {
            //       // 微信浏览器内置对象。参考微信官方文档
            //       if (document.addEventListener) {
            //         document.addEventListener(
            //           "WeixinJSBridgeReady",
            //           _this.onBridgeReady(res.data),
            //           false
            //         );
            //       } else if (document.attachEvent) {
            //         document.attachEvent(
            //           "WeixinJSBridgeReady",
            //           _this.onBridgeReady(res.data)
            //         );
            //         document.attachEvent(
            //           "onWeixinJSBridgeReady",
            //           _this.onBridgeReady(res.data)
            //         );
            //       }
            //     } else {
            //       console.log("准备调用微信支付");
            //       _this.onBridgeReady(res.data);
            //     }
            //   }
            // });
          } else if (sellRule == 3) {
            var data = {
              counts: 1,
              couponId: id,
              customerList: [
                {
                  phone: _this.userInfo.phone,
                  carNo: _this.chexin.carNo,
                  gid: _this.shangjia.id,
                  customerId: _this.userInfo.customerId,
                  customerName: _this.userInfo.userName,
                },
              ],
            };
            choicegrant(data).then((e) => {
              if (e.code == 200) {
                _this.$toast.success("领取成功！");
                _this.onLoad();
              }
            });
          } else if (sellRule == 99) {
            var data = {
              aid: aid,
              carNo: _this.chexin.carNo,
              phone: _this.userInfo.phone,
              uid: _this.userInfo.id,
            };
            manualbuy(data).then((e) => {
              if (e.code == 200) {
                _this.$toast.success("兑换成功！");
                _this.onLoad();
              }
            });
          }
          // else if(sellRule == 888){
          //   var data = {
          //     gid: gid,
          //     sid: _this.userInfo.id,
          //   };
          //   bringaccount(data).then((e) => {
          //     if (e.code == 200) {
          //       _this.$toast.success("绑定成功！");
          //       _this.onLoad();
          //     }
          //   });
          // }
        },
      });
    },
    getConfig() {
      let that = this;
      userModel
        .config({
          url: location.href.split("#")[0],
          gid: sessionStorage.getItem("gid"),
        })
        .then((res) => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: ["scanQRCode"],
            });
          } else {
            this.$toast(res.data.msg);
          }
        });
    },
  },
  created() {
    var _this = this;

    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.formadviser();
    //  var isWxMini = window.__wxjs_environment === 'miniprogram'
    // console.log(isWxMini)
    this.getConfig();
  },
  mounted() {
    var _this = this;
    if (sessionStorage.getItem("tanisok") == "true") {
      _this.show = true;
      sessionStorage.removeItem("tanisok");
      console.log(_this.chexin);
      if (
        _this.chexin == null ||
        _this.chexin.carNo == "" ||
        _this.chexin.carNo == null
      ) {
        _this.$toast("请先认证车牌再兑换！");
        setTimeout(() => {
          window.location.href = "/authentication";
        }, 1000);

        return;
      }
    }
    Bus.$on("to-news", function (data) {
      // console.log(data);
      sessionStorage.removeItem("tanisok");
      console.log(_this.chexin);
      if (
        _this.chexin == null ||
        _this.chexin.carNo == "" ||
        _this.chexin.carNo == null
      ) {
        _this.$toast("请先认证车牌再兑换！");
        setTimeout(() => {
          window.location.href = "/authentication";
        }, 1000);

        return;
      }
      _this.show = true;
    });
    window.addEventListener("pageshow", function (e) {
      // 通过persisted属性判断是否存在 BF Cache
      if (e.persisted) {
        // location.reload();
        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      }
    });
    if (localStorage.getItem("Authorization")) {
      this.onLoad();
    }
  },
  beforeDestroy() {
    Bus.$off("to-news");
  },
};
</script>
<style lang="less" scoped>
body {
  background: #ebedf0;
}
#app {
  background: #ebedf0;
}
.list_a {
  width: 20%;
  float: left;
  position: relative;
}
.list_a .van-icon {
  display: block;
  font-size: 24px;
}
.zuobiao {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 12px;
}
.m_yy_list {
  overflow: hidden;
  font-size: 13px;
}
.cika_name {
  font-weight: 600;
  position: relative;
  overflow: hidden;
}
.sj_name {
  font-size: 12px;
  color: #666;
}
.yu_tu {
  width: 40px;
  height: 40px;
  margin-top: 3px;
}
.m_yu_a {
  width: 49%;
  background: #fff;
  padding: 15px 12px 15px 12px;
  border-radius: 5px;
}

// ****************服务顾问
.m_gu {
  width: 49%;
  background: #fff;
  padding: 10px 0px 4px 10px;
  border-radius: 5px;
  overflow: hidden;
  height: 120px;
}
.fuwu_guwen {
  font-size: 16px;
}
.fuwu_biao {
  position: absolute;
  left: 0;
  margin-left: 60px;
  top: 0;
  // margin-top: 1px;
  background: #f5f5f5;
  font-size: 12px;
  border-radius: 4px;
  height: 22px;
  line-height: 22px;
  font-weight: bold;
  padding: 0 4px;
  -webkit-transform: scale(0.7);
  color: #dc143c;
}
.fuwtu {
  width: 14px;
  vertical-align: middle;
  margin-right: 2px;
}
.zuotou {
  width: 64px;
  text-align: center;
  color: #666;
  overflow: hidden;
}
.guwen_tou {
  width: 50px;
  display: block;
  margin: auto;
  margin-top: 6px;
  margin-bottom: 2px;
  border-radius: 25px;
}
.dian_img {
  width: 70px;
  display: block;
  margin-top: 10px;
  margin-right: 15px;
}
.mts {
  margin-top: 2px;
}
.xian_xin {
  overflow: hidden;
  font-size: 12px;
  // font-weight: bold;
  padding: 2px 0;
  padding-right: 16px;
  line-height: 15px;

  color: #333;
}
.qi_a {
  width: 80px;
  color: #666;
}
.chesp {
  font-size: 13px;
  padding: 0 8px;
  margin-left: 70px;
}
//************
.m_yu_b {
  width: 49%;
  background: #fff;
  border-radius: 5px;
}

.count {
  font-size: 16px;
  font-weight: bold;
}
.chengzhang {
  font-size: 10px;
  color: #fff;
  margin-top: 4px;
  background: url(../../assets/img/chenzhang.png);
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 11px;
  box-shadow: 0 1px 1px 0 rgba(106, 0, 5, 0.29);
  width: 96px;
  background-size: 100%;
}
.tu_ma {
  width: 40px;
  height: 40px;
}
.tou_a {
  float: left;
  height: 72px;
  width: 75px;
  position: relative;
}
.w_name {
  // float: left;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 110px;
  font-size: 18px;
  color: #fff;
  img {
    width: 26px;
    margin-right: 2px;
  }
}
.xin {
  width: 16px;
  height: 16px;
  float: left;
}
.heitie {
  font-size: 10px;
  line-height: 16px;
  color: #fff;
  float: left;
}
.xin_a {
  border-radius: 8px 2px 2px 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 42px;
  margin-left: 10px;
  background: #949494;
  height: 16px;
}
.hehuo {
  height: 21px;
  float: left;
}
.xin_b {
  position: absolute;
  bottom: 0;
  left: 0;
  // width: 42px;
  margin-left: 1px;
  height: 16px;
}
.kasimg {
  width: 100%;
  border-radius: 5px;
}
.touxiang {
  float: left;
  // position: absolute;
  // top: 0;
  // margin-top: 12%;
  // margin-left: 3%;
  display: inline-block;
  border-radius: 50%;
}
.bot_tou {
  position: absolute;
  top: 0;
  margin-top: 12%;
  margin-left: 3%;
}
.tuokema {
  margin-top: 2px;
  margin-left: 15px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
}
.soa {
  width: 28px;
  display: block;
  margin: auto;
}
.yuer_mien {
  // position: absolute;
  // top: 0;
  // right: 0;
  // margin-top: 15%;
  // margin-right: 20%;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
}
.yuer_h1 {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
.yuer_h2 {
  font-size: 12px;
  color: #fff;
  margin-top: 4px;
}
.chenz {
  float: left;
  margin-top: 6px;
  margin-left: 5px;
  text-align: left;
}
.toux {
  width: 63px;
  height: 63px;

  display: block;
  margin: auto;
  border-radius: 50%;
}
.dingdan_name {
  margin-left: 12px;
  font-weight: bold;
  font-size: 16px;
}
.dingdan_quanbu {
  font-size: 14px;
  margin-right: 12px;

  color: #666;
}
.list_c {
  overflow: hidden;
  margin-bottom: 15px;
}
.user-linkstop {
  color: #333;
  border-radius: 5px;
  overflow: hidden;
  padding: 10px 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
}
.list_top {
  // height: 68px;
  padding: 8px 0 5px 0;
}
.user-links {
  color: #333;

  border-radius: 5px;
  overflow: hidden;
}
.yuyue_list {
  overflow: hidden;
}
.m_lis {
  margin: 0px 6px 11px 6px;
}
// -----------------------------
.vip {
  position: relative;
  height: 154px;
}
.user-poster {
  height: 154px;
}
.hui {
  color: #fcc12d;
}
.ka_lis {
  font-size: 11px;
}
.chetie {
  position: absolute;
  left: 0;
  font-size: 12px;
  line-height: 35px;
  margin-left: 70px;
  color: #fffaf0;
}
.huiyuan_tiao {
  position: absolute;
  bottom: 0;
  height: 35px;
  width: 96%;
  left: 50%;
  margin-left: -48%;
}
.cimg {
  height: 35px;
  width: 100%;
}

.chexin div {
  text-align: left;

  line-height: 20px;
}
.ch1 {
  font-size: 20px;
  padding: 40px 0 0px 0;
}
.cckk {
  font-size: 13px;
  padding: 15px 0;
}
.xuanzheqi {
  border: 1px solid #dcdfe6;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  border-radius: 4px;
  width: 80%;
  // text-align: center;
  padding: 0 8px;
  margin-top: 15px;
  margin-bottom: 25px;
  margin-left: 10%;
}

.van-dropdown-menu__bar {
  box-shadow: unset !important;
}
.car_no {
  text-align: right;
  position: absolute;
  top: 12%;
  right: 5%;
  color: #fff;
}
.money {
  text-align: center;
  position: absolute;
  top: 45%;
  right: 0;
  color: #fff;
  font-size: 1.2rem;
}
.vip_l {
  text-align: left;
  position: absolute;
  bottom: 10%;
  left: 10%;
  color: #865e24;
}
.vip_r {
  text-align: right;
  position: absolute;
  bottom: 10%;
  right: 10%;
  color: #865e24;
}
.acvred {
  background: #dc143c;
  color: #fff;
}
.user {
  &-poster {
    width: 100%;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
  }
}
</style>

